














import { Component, Vue } from 'vue-property-decorator';
import router from "@/router";

@Component({
  components: {
  },
})
export default class UnauthorizedView extends Vue {

}
